import { useLocation, Link  } from 'react-router-dom';
import { useContext,useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import NavBar from '../../components/layout/NavBar';
import NavBtn from '../../components/layout/NavBtn';
import language from '../../variables/language';
import NavTheme from '../../components/layout/NavTheme';
import NavPersonal from '../../components/layout/NavPersonal';
import AuthContext from '../../providers/AuthContext';
import NavOpenBtn from '../../components/layout/NavOpenBtn';

//images 
import iconUsers from '../../img/icon-users.svg';
import imgUserPersonal from '../../img/plant.png';
import iconMenuOpen from '../../img/icon-menu-open.svg';


export default function Layout({children}) {

   const [iconTheme , setIconTheme] = useState();
   const [bgColor, setBgColor] = useState(localStorage.getItem("theme-bgColor"));
   const [bgPrimaryColor, setBgPrimaryColor] = useState(localStorage.getItem("theme-bgPrimaryColor"));
   const [bgSecondaryFillColor, setBgSecondaryFillColor] = useState(localStorage.getItem("theme-bgSecondaryFillColor"));
   const [mainTextColor, setMainTextColor] = useState(localStorage.getItem("theme-mainTextColor"));
   const [secondaryTextColor, setSecondaryTextColor] = useState(localStorage.getItem("theme-secondaryTextColor"));
   const [bgButtonsColor, setBgButtonsColor] = useState(localStorage.getItem("theme-bgButtonsColor"));
   const [bgModalColor, setBgModalColor] = useState(localStorage.getItem("theme-bgModalColor"));
   const [bgModalColor2, setBgModalColor2] = useState(localStorage.getItem("theme-bgModalColor2"));
   const [bgGreef, seBgGreef] = useState(localStorage.getItem("theme-bgGreef"));
   const [bgTableColor, seBgTableColor] = useState(localStorage.getItem("theme-bgTableColor"));
   const [bgInputsColor, seBgInputsColor] = useState(localStorage.getItem("theme-bgInputsColor"));

   const {firstName,setFirstName,lastName, setLastName} = useContext(AuthContext);
   const { theme ,setTheme } = useContext(DetailsContext);
   const [navBar , setNavBar ] = useState( window.screen.width < 900? localStorage.getItem("nav-bar-open")==='true'?'0':'3' : localStorage.getItem("nav-bar-open")==='true'?'0':localStorage.getItem("nav-bar-open"));
   const [widthNav, setWidthNav] = useState(localStorage.getItem("nav-bar-open")==='0'?'60px':'0px');
   const [widthText, setWidthText] = useState('fit-content');

   const location = useLocation();

   const changeColor = () => {
      setBgColor(`hsl(240, 11%, 23%)`); 
    };
   
    useEffect(() => {
      init();
    }, []);

   const init = () => {
      
      if(navBar==='1'){
         setWidthNav("300px");
         setWidthText("fit-content");
      }else if(navBar==='0'){
         setWidthNav("60px");
         setWidthText("0px");
      }else{
         setWidthNav("0px");
         setWidthText("0px");
      }
   }

   const handlerTheme = () => {
      if(theme==='true'){
         setTheme('false');
         localStorage.setItem("theme",'false');
         setBgColor(`#1e2322 `);
         setBgPrimaryColor('linear-gradient(333deg, rgb(18 20 22) 1%, rgb(52 97 91) 100%)');
         setBgSecondaryFillColor('rgb(218, 238, 252)');
         setMainTextColor('#f3f3f3');
         setSecondaryTextColor('#f3f3f3f0');
         setBgButtonsColor('radial-gradient(circle, rgb(0 0 0 / 98%) 0%, rgb(54 75 77) 100%)');
         setBgModalColor('rgba(14, 14, 14, 0.478)');
         setBgModalColor2('linear-gradient(45deg, rgba(2, 29, 27, 0.88), rgba(1, 9, 26, 0.62))');
         seBgGreef('rgb(21 43 31 / 23%)');
         seBgTableColor('rgb(10 20 18 / 52%)');
         seBgInputsColor('rgb(59 63 69 / 34%)');
         localStorage.setItem("theme-bgColor",'#1e2322 ');
         localStorage.setItem("theme-bgPrimaryColor",'linear-gradient(333deg, rgb(18 20 22) 1%, rgb(52 97 91) 100%)');
         localStorage.setItem("theme-bgSecondaryFillColor",'rgb(218, 238, 252)');
         localStorage.setItem("theme-mainTextColor",'#f3f3f3');
         localStorage.setItem("theme-secondaryTextColor",'#f3f3f3f0');
         localStorage.setItem("theme-bgButtonsColor",'radial-gradient(circle, rgb(0 0 0 / 98%) 0%, rgb(54 75 77) 100%)');
         localStorage.setItem("theme-bgModalColor",'rgba(14, 14, 14, 0.88)');
         localStorage.setItem("theme-bgModalColor2",'linear-gradient(45deg, rgba(2, 29, 27, 0.88), rgba(1, 9, 26, 0.62))');
         localStorage.setItem("theme-bgGreef",'rgb(21 43 31 / 23%)');
         localStorage.setItem("theme-bgTableColor",'rgb(10 20 18 / 52%)');
         localStorage.setItem("theme-bgInputsColor",'rgb(59 63 69 / 34%)');
      }else{
         setTheme('true');
         localStorage.setItem("theme",'true');
         setBgColor(`white`);
         setBgPrimaryColor(`linear-gradient(110deg, rgb(229 155 255) 0%, rgb(132 222 255 / 98%) 100%)`);
         setBgSecondaryFillColor('rgb(79, 131, 168)');
         setMainTextColor('#2f343d');
         setSecondaryTextColor('#2f343df0');
         setBgButtonsColor('radial-gradient(circle, rgb(255 255 255) 0%, rgb(210 247 255) 80%)');
         setBgModalColor('rgba(255, 255, 255, 0.478)');
         setBgModalColor2('linear-gradient(45deg, rgb(227 255 253 / 93%), rgb(223 233 255 / 84%))');
         seBgGreef('rgb(234 249 255 / 53%)');
         seBgTableColor('rgb(235 245 251 / 48%)');
         seBgInputsColor('rgb(255 255 255)');
         localStorage.setItem("theme-bgColor",'white');
         localStorage.setItem("theme-bgPrimaryColor",'linear-gradient(110deg, rgb(229 155 255) 0%, rgb(132 222 255 / 98%) 100%)');
         localStorage.setItem("theme-bgSecondaryFillColor",'rgb(79, 131, 168)');
         localStorage.setItem("theme-mainTextColor",'#2f343d');
         localStorage.setItem("theme-secondaryTextColor",'#2f343df0');
         localStorage.setItem("theme-bgButtonsColor",'radial-gradient(circle, rgb(255 255 255) 0%, rgb(210 247 255) 80%)');
         localStorage.setItem("theme-bgModalColor",'rgba(255, 255, 255, 0.88)');
         localStorage.setItem("theme-bgModalColor2",'linear-gradient(45deg, rgb(227 255 253 / 93%), rgb(223 233 255 / 84%))');
         localStorage.setItem("theme-bgGreef",'rgb(234 249 255 / 53%)');
         localStorage.setItem("theme-bgTableColor",'rgb(235 245 251 / 48%)');
         localStorage.setItem("theme-bgInputsColor",'rgb(255 255 255)');
      }
   };
   const handlerNavBar = (e) => {
      e.stopPropagation();
      if(navBar==='1'){
         setNavBar('0');
         setWidthNav("60px");
         setWidthText("0px");
         localStorage.setItem('nav-bar-open', 0);
      }else {
         setNavBar('1');
         setWidthNav("300px");
         setWidthText("fit-content");
         localStorage.setItem('nav-bar-open', 1);
      }
   };
   const handlerOffNavBar = (e) => {
      e.stopPropagation();
      if(navBar!=='3'){
         setNavBar('3');
         setWidthNav("0px");
         setWidthText("0px");
         localStorage.setItem('nav-bar-open', 3);
      }else{
         setNavBar('0');
         setWidthNav("60px");
         setWidthText("0px");
         localStorage.setItem('nav-bar-open', 0);
      }
   };
   

 
   return (
      <div className='wrapper' style={
         {
            '--bg-color':bgColor,
            '--bgprimary-color':bgPrimaryColor,
            '--secondary-fill-color':bgSecondaryFillColor,
            '--maintext-color':mainTextColor,
            '--secondarytext-color': secondaryTextColor ,
            '--bg-buttons-color':bgButtonsColor,
            '--width-nav': widthNav ,
            '--nav-text-width': widthText ,
            '--bg-modal-color': bgModalColor ,
            '--bg-modal-color2': bgModalColor2 ,
            '--theme-bgGreef': bgGreef ,
            '--bg-table-color': bgTableColor ,
            '--bg-inputs-color': bgInputsColor ,
           
         }
         }>
         
         <NavBar className="nav-bar no-select"  >
            <NavOpenBtn path={iconMenuOpen} onClick={handlerNavBar} name="menu"/>
            
            <NavPersonal path={imgUserPersonal} firstName={firstName } lastName={lastName}/>
            <Link  to='/dashboard'>
               <NavBtn name={language[language.lang].dashboard} path={iconUsers} location={location.pathname.indexOf('dashboard') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" ><path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z"/>
               </svg>
               </NavBtn>
            </Link>
            
            <Link  to='/set'>
               <NavBtn name={language[language.lang].set} path={iconUsers} location={location.pathname.indexOf('set') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" ><path d="M600-160v-80H440v-200h-80v80H80v-240h280v80h80v-200h160v-80h280v240H600v-80h-80v320h80v-80h280v240H600Zm80-80h120v-80H680v80ZM160-440h120v-80H160v80Zm520-200h120v-80H680v80Zm0 400v-80 80ZM280-440v-80 80Zm400-200v-80 80Z"/>
               </svg>
               </NavBtn>
            </Link>
            <Link  to='/games'>
               <NavBtn name={language[language.lang].games} path={iconUsers} location={location.pathname.indexOf('games') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" ><path d="M182-200q-51 0-79-35.5T82-322l42-300q9-60 53.5-99T282-760h396q60 0 104.5 39t53.5 99l42 300q7 51-21 86.5T778-200q-21 0-39-7.5T706-230l-90-90H344l-90 90q-15 15-33 22.5t-39 7.5Zm16-86 114-114h336l114 114q2 2 16 6 11 0 17.5-6.5T800-304l-44-308q-4-29-26-48.5T678-680H282q-30 0-52 19.5T204-612l-44 308q-2 11 4.5 17.5T182-280q2 0 16-6Zm482-154q17 0 28.5-11.5T720-480q0-17-11.5-28.5T680-520q-17 0-28.5 11.5T640-480q0 17 11.5 28.5T680-440Zm-80-120q17 0 28.5-11.5T640-600q0-17-11.5-28.5T600-640q-17 0-28.5 11.5T560-600q0 17 11.5 28.5T600-560ZM310-440h60v-70h70v-60h-70v-70h-60v70h-70v60h70v70Zm170-40Z"/>
               </svg>
               
               </NavBtn>
            </Link>
            <Link  to='/clients'>
               <NavBtn name={language[language.lang].clients} path={iconUsers} location={location.pathname.indexOf('clients') === -1 ? '' : 'focused'}>
                  <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" >
                     <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z" />
                  </svg>
               </NavBtn>
            </Link>
            <Link  to='/pipeline'>
               <NavBtn name={language[language.lang].pipeline} path={iconUsers} location={location.pathname.indexOf('pipeline') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" ><path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z"/>
               </svg>
               </NavBtn>
            </Link>
            <Link  to='/schedule'>
            
               <NavBtn name={language[language.lang].schedule} path={iconUsers} location={location.pathname.indexOf('schedule') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" ><path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/>
               </svg>
               </NavBtn>
            </Link>

            <Link  to='/projects'>
               <NavBtn name={language[language.lang].projects} path={iconUsers} location={location.pathname.indexOf('projects') === -1 ? '' : 'focused'}>
              
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'viewBox="0 -960 960 960" ><path d="m260-520 220-360 220 360H260ZM700-80q-75 0-127.5-52.5T520-260q0-75 52.5-127.5T700-440q75 0 127.5 52.5T880-260q0 75-52.5 127.5T700-80Zm-580-20v-320h320v320H120Zm580-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-500-20h160v-160H200v160Zm202-420h156l-78-126-78 126Zm78 0ZM360-340Zm340 80Z"/>
               </svg>
               </NavBtn>
            </Link>

            <Link  to='/users'>
               <NavBtn name={language[language.lang].users} path={iconUsers} location={location.pathname.indexOf('users') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" ><path d="M160-80q-33 0-56.5-23.5T80-160v-440q0-33 23.5-56.5T160-680h200v-120q0-33 23.5-56.5T440-880h80q33 0 56.5 23.5T600-800v120h200q33 0 56.5 23.5T880-600v440q0 33-23.5 56.5T800-80H160Zm0-80h640v-440H600q0 33-23.5 56.5T520-520h-80q-33 0-56.5-23.5T360-600H160v440Zm80-80h240v-18q0-17-9.5-31.5T444-312q-20-9-40.5-13.5T360-330q-23 0-43.5 4.5T276-312q-17 8-26.5 22.5T240-258v18Zm320-60h160v-60H560v60Zm-200-60q25 0 42.5-17.5T420-420q0-25-17.5-42.5T360-480q-25 0-42.5 17.5T300-420q0 25 17.5 42.5T360-360Zm200-60h160v-60H560v60ZM440-600h80v-200h-80v200Zm40 220Z"/>
               </svg>
               </NavBtn>
            </Link>
            <Link  to='/students'>
               <NavBtn name={language[language.lang].students} path={iconUsers} location={location.pathname.indexOf('students') === -1 ? '' : 'focused'}>
              
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav' viewBox="0 -960 960 960" ><path d="M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z"/>
               </svg>
               </NavBtn>
            </Link>
            <Link  to='/conversations'>
           
               <NavBtn name={language[language.lang].conversations} path={iconUsers} location={location.pathname.indexOf('conversations') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav' viewBox="0 0 24 24" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3s1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3S21.66,9,20,9z M18,19L6,19V7h12V19z M9,13c-0.83,0-1.5-0.67-1.5-1.5 S8.17,10,9,10s1.5,0.67,1.5,1.5S9.83,13,9,13z M16.5,11.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S14.17,10,15,10 S16.5,10.67,16.5,11.5z M8,15h8v2H8V15z"/></g></g>
               </svg>
               </NavBtn>
            </Link>
            <Link  to='/groups'>
        
               <NavBtn name={language[language.lang].groups} path={iconUsers} location={location.pathname.indexOf('groups') === -1 ? '' : 'focused'}>
           
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav' viewBox="0 -960 960 960" fill="#e8eaed"><path d="M411-480q-28 0-46-21t-13-49l12-72q8-43 40.5-70.5T480-720q44 0 76.5 27.5T597-622l12 72q5 28-13 49t-46 21H411Zm24-80h91l-8-49q-2-14-13-22.5t-25-8.5q-14 0-24.5 8.5T443-609l-8 49ZM124-441q-23 1-39.5-9T63-481q-2-9-1-18t5-17q0 1-1-4-2-2-10-24-2-12 3-23t13-19l2-2q2-19 15.5-32t33.5-13q3 0 19 4l3-1q5-5 13-7.5t17-2.5q11 0 19.5 3.5T208-626q1 0 1.5.5t1.5.5q14 1 24.5 8.5T251-596q2 7 1.5 13.5T250-570q0 1 1 4 7 7 11 15.5t4 17.5q0 4-6 21-1 2 0 4l2 16q0 21-17.5 36T202-441h-78Zm676 1q-33 0-56.5-23.5T720-520q0-12 3.5-22.5T733-563l-28-25q-10-8-3.5-20t18.5-12h80q33 0 56.5 23.5T880-540v20q0 33-23.5 56.5T800-440ZM0-240v-63q0-44 44.5-70.5T160-400q13 0 25 .5t23 2.5q-14 20-21 43t-7 49v65H0Zm240 0v-65q0-65 66.5-105T480-450q108 0 174 40t66 105v65H240Zm560-160q72 0 116 26.5t44 70.5v63H780v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5Zm-320 30q-57 0-102 15t-53 35h311q-9-20-53.5-35T480-370Zm0 50Zm1-280Z"/>
               </svg>
               </NavBtn>
            </Link>
            <Link  to='/settings'>
               <NavBtn name={language[language.lang].settings} path={iconUsers} location={location.pathname.indexOf('settings') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg"  className='img-nav' viewBox="0 -960 960 960" ><path d="m234-480-12-60q-12-5-22.5-10.5T178-564l-58 18-40-68 46-40q-2-13-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T222-820l12-60h80l12 60q12 5 22.5 10.5T370-796l58-18 40 68-46 40q2 13 2 26t-2 26l46 40-40 68-58-18q-11 8-21.5 13.5T326-540l-12 60h-80Zm40-120q33 0 56.5-23.5T354-680q0-33-23.5-56.5T274-760q-33 0-56.5 23.5T194-680q0 33 23.5 56.5T274-600ZM592-40l-18-84q-17-6-31.5-14.5T514-158l-80 26-56-96 64-56q-2-18-2-36t2-36l-64-56 56-96 80 26q14-11 28.5-19.5T574-516l18-84h112l18 84q17 6 31.5 14.5T782-482l80-26 56 96-64 56q2 18 2 36t-2 36l64 56-56 96-80-26q-14 11-28.5 19.5T722-124l-18 84H592Zm56-160q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"/>
               </svg>
               </NavBtn>
            </Link>
            <Link  to='/logout'>
               <NavBtn name={language[language.lang].logout} path={iconUsers} location={location.pathname.indexOf('logout') === -1 ? '' : 'focused'}>
               <svg xmlns="http://www.w3.org/2000/svg" className='img-nav'  viewBox="0 -960 960 960" ><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"/>
               </svg>
               </NavBtn>
            </Link>
            <NavTheme onClick={handlerTheme} path={iconUsers} isLight={theme} />
         </NavBar>
         <div className='hide-nav-btn' onClick={handlerOffNavBar} style={{position:'fixed', right:-50, bottom:40, width:100, height:100,
          borderRadius: `50% 0 0 50%`,backdropFilter:`blur(2px)` }} ></div>
         <div className='wrap-container'   style={{minWidth:'available',width:"available",}}>
            {children}
         </div>

      </div>
   );
 }
