

import './App.css';
import LoginForm from './pages/login/LoginForm';
import Logout from './pages/logout/Logout';
import Button from './components/Button';
import AuthContext from './providers/AuthContext';
import DetailsContext from './providers/DetailsContext';
import { BrowserRouter as Router, Routes, Route , useLocation, useNavigate } from 'react-router-dom';
import Clients from './pages/klienci/Index';
import Settings from './pages/settings/Index';
import axios from 'axios';
import { useEffect, useState } from 'react';
import language from './variables/language';
import Users from './pages/users/Index';
import Pipeline from './pages/pipeline/Index';
import Schedule from './pages/schedule/Index';
import Dashboard from './pages/dashboard/Index';
import Cars from './pages/students/Index';
import Projects from './pages/projekts/Index';
import Card from './pages/klienci/Card';
import CarDocuments from './pages/students/StudentDocuments';
import Students from './pages/students/Index';
import Conversations from './pages/conversations/Index';
import Answers from './pages/conversations/Answers';
import Messages from './pages/conv-progress/Messages';
import StudentCard from './pages/students/StudentCard';
import Groups from './pages/groups/Index';
import Games from './pages/games/Index';
import Grid from './pages/grid/Index';
import GridCard from './pages/grid/GridCard';
import Quiz from './pages/quiz/Index';
import QuizCard from './pages/quiz/QuizCard';
import Letters from './pages/letters/Index';
import LettersCard from './pages/letters/LettersCard';
import Matching from './pages/matching/Index';
import MatchingCard from './pages/matching/MathchingCard';
import SetCard from './pages/set/SetCard';
import Sets from './pages/set/Index';


function App() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [lang,setLang] = useState("en");
  language.lang = lang;
  const dtf = new Intl.DateTimeFormat();
  const [timeZone,setTimeZone] = useState(dtf.resolvedOptions().timeZone);
  
  const browserLanguage = navigator.language || navigator.userLanguage;
 

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userId, setUserId] = useState('');
  const [theme, setTheme] = useState(localStorage.getItem("theme")===null?true:localStorage.getItem("theme"));


  const navigate = useNavigate();
  const location = useLocation();


  //initialization bar 
  if(localStorage.getItem("nav-bar-open")===null){
    localStorage.setItem("nav-bar-open",true);
  }
  if(localStorage.getItem("theme-bgColor")===null){
    localStorage.setItem("theme",'true');
    localStorage.setItem("theme-bgColor",'hsl(240, 7%, 24%);');
    localStorage.setItem("theme-bgPrimaryColor",'hsl(240, 16%, 11%)');
    localStorage.setItem("theme-bgSecondaryFillColor",'#BCBCBC');
    localStorage.setItem("theme-mainTextColor",'#f3f3f3');
  }
  
  // setUserId(1);
  // setIsLoggedIn(true);
  // setFirstName("Simon");
  // setLastName("Ketana");
  // navigate(location.pathname.indexOf('login') === -1 ? location.pathname : '/');

  useEffect(() => {
   if (!isLoggedIn) {
   
      axios.get('/api/islogin', {
        withCredentials: true,
    })
      .then(response => { 
        setUserId(response.data.id);
        setIsLoggedIn(true);
        setFirstName(response.data.imie);
        setLastName(response.data.nazwisko);
        navigate(location.pathname.indexOf('login') === -1 ? location.pathname : '/');
      })
      .catch(err => {
        console.log(err);
        navigate('/login');
      });
    }
   }, [isLoggedIn, navigate, API_URL, location.pathname]);



  return (
    <div className="App" >
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, firstName, setFirstName,
           lastName, setLastName, userId, setUserId ,timeZone , setTimeZone }}>
          <DetailsContext.Provider value={{ theme, setTheme, lang, setLang  }}>
           
              <Routes >
                <Route index  element={<Dashboard />}/>
                <Route path='/dashboard' element={<Dashboard/>}/>
                <Route path='/games' element={<Games/>}/>
                <Route path='/grid' element={<Grid/>}/>
                <Route path='/set' element={<Sets/>}/>
                <Route path='/quiz' element={<Quiz/>}/>
                <Route path='/letters' element={<Letters/>}/>
                <Route path='/matching' element={<Matching/>}/>
                <Route path='/gridcards/:id' element={<GridCard/>}/>
                <Route path='/setcards/:id' element={<SetCard/>}/>
                <Route path='/quizcards/:id' element={<QuizCard/>}/>
                <Route path='/letters/:id' element={<LettersCard/>}/>
                <Route path='/matchexamples/:id' element={<MatchingCard/>}/>
                <Route path='/clients' element={<Clients/>}/>
                <Route path='/clients/:id' element={<Card/>}/>
                
                <Route path='/pipeline' element={<Pipeline/>}/>
                <Route path='/schedule' element={<Schedule/>}/>
                <Route path='/projects' element={<Projects/>}/>
                <Route path='/users' element={<Users/>}/>
                <Route path='/students' element={<Students/>}/>
                <Route path='/groups' element={<Groups/>}/>
                <Route path='/students/:id' element={<StudentCard/>}/>
              
                <Route path='/cardocuments/bycar/:id' element={<CarDocuments/>}/>
                <Route path='/conversations' element={<Conversations/>}/>
                <Route path='/conversations/:id' element={<Answers/>}/>
                <Route path='//messages/byconvprogress/:id' element={<Messages/>}/>
                <Route path='/login' element={<LoginForm />}/>
                <Route path='/logout' element={<Logout />}/>
                <Route path='/settings' element={<Settings />}/>
              </Routes>
           
          </DetailsContext.Provider>
        </AuthContext.Provider>
    </div>
  );
}

export default App;
