const language = {
    lang:'pl',
    en: {
      id:'Id',
      pages:{
        login:{
          loginTitle:"Log in",
          loginPlaceholder:"login...",
          passPlaceholder:"password...",
          loginButton:"Login"
        },
      },
      meta:"Name ",
      type:"Type",
      answer:"Answer",
      emailVerificationPromptText : 'The email is not correct format ! '  ,
      clients : "Clients",
      client : "Client",
      dashboard : "Dashboard",
      users : "Users",
      pipeline : "Pipeline",
      schedule : "Schedule",
      projects : "Projects",
      project : "Project",
      stage:"Stage",
      dLastContact:"Last contact",
      address:"Address",
      age:"Age",
      probability:"Probability",
      language:"Language",
      category:"Category",
      product:"Product",
      fill:"...",
      groupe:"Group",
      groups:"Groups",
      resources:"Resources",
      resources:"Resources",
      teacher:"Teacher",
      price:"Price",
      source:"Source",
      gMeetId:"Gmeet ID",
      telegramUsername:"Telegram",
      set:"Sets",
      grid:"Game",
      modals:{
        conversationprogress:{
          createTitle:"You can add quiz here",
          updateDescription: "You can edit quiz here",
          updateTitle : "Quiz update",
        },
        events:{
          createTitle:"You can add event here",
          updateDescription: "You can edit event here",
          updateTitle : "Event update",
        },
        answers:{
          createTitle:"You can add message here",
          updateDescription: "You can edit message here",
          updateTitle : "Message update",
        },
        conversations:{
          createTitle:"You can add conversation here",
          updateDescription: "You can edit conversation here",
          updateTitle : "Conversation update",
        },
        
        students:{
          createTitle:"You can add student here",
          updateDescription: "You can edit student here",
          updateTitle : "Student update",
        },
        carDocuments:{
          createTitle:"Tutaj możesz dodać document",
          updateDescription: "Pola nie można edytować",
          updateTitle : "Edycja documentu nie mozliwa",
        },
        projects:{
          createTitle:"You can add project here",
          updateDescription: "Project update",
          updateTitle : "You can edit project here",
        },
       users:{
        createTitle:"You can add user here",
        updateDescription: "You can edit user here",
        updateTitle : "User update",
       },
       clients:{
        createTitle:"You can add client here",
        updateDescription: "You can edit client here",
        updateTitle : "Client update",
       },
       lessons:{
        createTitle:"You can add client here",
        updateDescription: "You can edit client here",
        updateTitle : "Client update",
       },
       games:{
        createTitle:"You can add client here",
        updateDescription: "You can edit client here",
        updateTitle : "Client update",
       }
      },
      tasks : "Tasks",
      games : "Games",
      value:"Value",
      conversations:"Bot",
      carDocuments : "Dokumenty",
      mark:"Mark",
      registrNum:"Register num",
      color:"Color",
      students : "Students",
      model : "Model",
      settings : "Settings",
      logout : "Logout",
      logging : "Logined from",
      firstName : "First name",
      lastName : "Last name",
      surname : "Last name",
      email : "Email",
      tel : "Tel",
      device : "Device",
      timezone : "Time zone",
      lastActivity : "Last activity",
      placeholderSearch : "search here ...",
      save : "Save",
      dateBirth:"Data of birth",
      position:"Position",
      pl:"Polish",
      en:"English",
      icons:"",
      feedbacks:{
        done:"Successfully done !",
        error:"Something went wrong !",
        wait:"Processing...",
        timeout:"The request time of server answering is out !",
        forbidden:"Access deny!",
      },
      name:"Name",
      description:"Description",
      responsibleId:"Responsible",
      responsible:"Responsible",
      facebook:"Facebook",
      hashCode:"Hash Code",
      conversationTypes:"Conversation Type",
      isPresent:"Published",
      level:"Level",
      typeId:"Type",
      answers:"Answers",
      estimationGroup:"Esti Group",
      answerTypeId:"Type",
      conversation:"Conversation",
      isLast:"Is last",
      text:"Text",
      point:"Scores",
      conversationId:"Conversation",
      isRight:"Is Right",
      url:"Url",
      fromId:"Coming from",
      nextId:"Going to",
      answerId:"Question",
      estimationFunction:"Estimation function",
      clasification:"Classification",
      outerUserId:"User from Telegram",
      isFinished:"Is finished",
      isBot:"Is Bot",
      flagId:"Flag Id",
      dateTime:"Time stamp",
      messages:"Messages",
      answerConnector:"Scores | Right | EstiGroup",
      date:'Date',
      course:'Plan',
      homework:'Homework',
      comment:'Comment',
      notes:'Notes',
      toPay:'To Pay',
      attendence:'Att-ce',
      extra:'Extra',
      student:'Student',
      duration:'Duration',
      start:'Start',
      end:'End',
      isPaid:'Is paid',
      schedule:'Schedule',
      ocupation:'Ocupation',
      location:'Location',
      gdocument:'G_Document',
      isForMe:'Is For Me',
      gDictionaryUa:'Dictionay UA',
      gDictionaryEng:'Dictionay Eng',
      algotithm:'Algorithm',
      find:'Find',
      dateFrom:"Date from",
      dateTo:"Date to",
      isCycled:"Is cycled",
      hasBroadcast:"Has Broadcast",
    },
    pl: {
      id:'Id',
      pages:{
        login:{
          loginTitle:"Zaloguj się",
          loginPlaceholder:"login...",
          passPlaceholder:"hasło...",
          loginButton:"Login"
        },
      },
      meta:"Imie ",
      type:"Typ",
      answers:"Odpowiedz",
        emailVerificationPromptText : 'Nie poprawny format emailu !'  ,
        clients : "Klienci",
        client : "Klient",
        dashboard : "Dashboard",
        users : "Użytkownicy",
        stage:"Etap",
        dLastContact:"Ostatni konakt",
        address:"Adres",
        age:"Wiek",
        probability:"Wiarygodnosc",
        language:"Język",
        category:"kategoria",
        product:"Produkt",
        price:"Cena",
        source:"Źródło",
        fill:"...",
        gMeetId:"Gmeet ID",
        telegramUsername:"Telegram",
        set:"Sety",
        grid:"Grid",
        
        modals:{
          conversationprogress:{
            createTitle:"Tutaj możesz dodać quiz",
            updateDescription: "Tutaj możesz zaktualizować quiz",
            updateTitle : "Edycja quizu",
          },
          events:{
            createTitle:"Tutaj możesz dodać wydarzenie",
            updateDescription: "Tutaj możesz zaktualizować wydarzenie",
            updateTitle : "Edycja wydarzenia",
          },
          answers:{
            createTitle:"Tutaj możesz dodać wiadomość",
            updateDescription: "Tutaj możesz zaktualizować wiadomość",
            updateTitle : "Edycja wiadomości",
          },
          conversations:{
            createTitle:"Tutaj możesz dodać rozmowe",
            updateDescription: "Tutaj możesz zaktualizować rozmowe",
            updateTitle : "Edycja rozmowy",
          },
          students:{
            createTitle:"Tutaj możesz dodać studenta",
            updateDescription: "Tutaj możesz zaktualizować studenta",
            updateTitle : "Edycja studenta",
          },
          carDocuments:{
            createTitle:"Tutaj możesz dodać document",
            updateDescription: "Pola nie można edytować",
            updateTitle : "Edycja documentu nie mozliwa",
          },
          projects:{
            createTitle:"Tutaj możesz dodać projekt",
            updateDescription: "Tutaj możesz zaktualizować projekt",
            updateTitle : "Edycja projektu",
          },
         users:{
          createTitle:"Tutaj możesz dodać uzytkownika",
          updateDescription: "Tutaj możesz zaktualizować uzytkownika",
          updateTitle : "Edycja uzytkownika",
         },
         clients:{
          createTitle:"Tutaj możesz dodać klienta",
          updateDescription: "Tutaj możesz zaktualizować klienta",
          updateTitle : "Edycja klienta",
         },
         games:{
          createTitle:"Tutaj możesz dodać gre",
          updateDescription: "Tutaj możesz zaktualizować gre",
          updateTitle : "Edycja gry",
         }
        },
        value:"Znaczenie",
        pipeline : "Pipeline",
        projects : "Projekty",
        games : "Games",
        project : "Projekt",
        conversations:"Bot",
        mark:"Marka",
        registrNum:"Numer rejestracyjny",
        carRegistrNum:"Numer rejestracyjny",
        color:"Color",
        tasks : "Zadania",
        carDocuments : "Dokumenty",
        model : "Model",
        students : "Studenci",
        number:"Numer Documentu",
        dateFrom:"Data od",
        dateTo:"Data do",
        settings : "Ustawienia",
        logout : "Wyloguj",
        logging : "Logowania z urądzeń",
        firstName : "Imie",
        lastName : "Nazwisko",
        surname : "Nazwisko",
        email : "Email",
        tel : "Tel",
        device : "Urządzenie",
        timezone : "Strefa czasowa",
        lastActivity : "Ostatnia aktywność",
        placeholderSearch : "wyszukaj ...",
        save : "Zapisz",
        dateBirth:"Data urodzenia",
        position:"Stanowisko",
        pl:"Polski",
        en:"Angielski",
        icons:"",
        feedbacks:{
          done:"Pomyślnie wykonano !",
          error:"Wystąpil problem !",
          wait:"Przetwazanie rządania...",
          timeout:"Za długi czas oczekiwania na odpowiedż od serwera !",
          forbidden:"Brak uprawnienia !",
        },
        name:"Nazwa",
        description:"Opis",
        responsibleId:"Odpowiedzialny",
        responsible:"Odpowiedzialny",
        facebook:"Facebook",
        hashCode:"Hash Code",
        conversationTypes:"Typ rozmowy",
        isPresent:"Opublikowane",
        level:"Poziom",
        typeId:"Typ",
        answers:"Pytania",
        estimationGroup:"Grupa oceny",
        answerTypeId:"Typ",
        conversation:"Rozmowa",
        isLast:"Ostatni",
        text:"Text",
        point:"Punkty",
        conversationId:"Rozmowa",
        isRight:"Poprawna",
        url:"Url",
        fromId:"Z odpowiedzi",
        nextId:"Do odpowiedzi",
        answerId:"Pytanie",
        estimationFunction:"Funkja oceny ",
        clasification:"Klasyfikacja",
        outerUserId:"User z Telegram",
        isFinished:"Czy zakończony",
        isBot:"Czy od bota",
        flagId:"Flag Id",
        dateTime:"Time stemp",
        messages:"Powiadomienia",
        answerConnector:"Pointy i czy poprawna",
        date:'Date',
        course:'Course',
        homework:'Homework',
        comment:'Comment',
        extra:'Extra',
        student:'Student',
        duration:'Czas trwania',
        start:'Początek',
        end:'Koniec',
        isPaid:'Opłacono',
        schedule:'Rozklad',
        ocupation:'Zajetość',
        location:'Lokacja',
        gdocument:'G_Document',
        isForMe:'Dla mnie',
        gDictionaryUa:'Słownik UA',
        gDictionaryEng:'Słownik Eng',
        algotithm:'Algorithm',
        find:'Szukaj',
        isCycled:"Czy cykliczny",
        hasBroadcast:"Jest powiadomienie",
    }
}
export default language;