import axios from 'axios';
import Layout from '../shared/Layout';
import Form from '../../components/forms/Form';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import ActivityList from '../../components/settings/ActivityList';
import language from '../../variables/language';
import { useLocation, Link, useParams  } from 'react-router-dom';

import Header from '../shared/Header';
import ModalUpdate from '../shared/ModalUpdate';


const SetCard = () => {

  const { id } = useParams();
  const label = 'users';
  const location = useLocation();

 
  const [modalState ,setModalState] = useState('none');
  const [headers, setHeaders] = useState(
    { 'name':{},'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/setcard','value' : '','mode':'delete'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':'/api/setcard','value' : '','mode':'edit'},
     
    }}
    );
    const [mixedinputs, setMixednputs] = useState(
      {
  
       'set':{'name':'set','type' : 'number', 'tagtype' : 'select','url':'/api/set','value' : id,'disabled':true,'width':'49%'},
       'grid':{'name':'grid','type' : 'number', 'tagtype' : 'select','url':'/api/grid','value' : id,'disabled':false,'width':'49%'},
    
      });
  
  const [selectors, setSelectors] = useState({
  });
  const [inputsToUpdate, setInputsToUpdate] = useState(
    {'value':{'name':'value','type' : 'text','value' : ''},
});
  const [selectorsToUpdate, setSelectorsToUpdate] = useState({
  });
  const [icons, setIcons] = useState({
    'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/setcard','value' : ''},
    'icon-edit':{'name':'icon-edit','type' : 'icon','url':'/api/setcard','value' : ''},
  });
  const [closerForm, setCloserForm] = useState(0);

  const formDoneHundler = () => {
    setCloserForm('0px');
  }
  const [toUpdateId , setToUpdateId] = useState('');
  const onRefreshHundler = ()=> {
    if(closerForm==="1px"){
      setCloserForm('0px');
    }else{
      setCloserForm('1px');
    }
    
  }
  const stateHundler = (state,id) => {
    if(state === 'edit'){
      setModalState('edit');
      setToUpdateId(id);
    }else if(state === 'none'){
      setModalState('none');
    }
  }

  return (
    <Layout>
      <Header name={'Game of set: '}   opened='300px' >
        <Form inputs={mixedinputs} 
        url='/api/setcard' onRefreshHundler={onRefreshHundler} 
        name='Tutaj możesz dodać nowego grida'></Form>
      </Header>
      <main className='main-block' >
        <div className='main-content txt-c' >
        <ActivityList name='' url={'/api/setcard?set='+id} done={closerForm} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
        </div>  
        <ModalUpdate modalState={modalState} inputs={inputsToUpdate} selectors={selectorsToUpdate} url='/api/setcard' toUpdate={'/api/setcard/'+ toUpdateId } done={formDoneHundler} onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
      </main>

    </Layout>
  )
}

export default SetCard